import state from './moduleCompanyState.js'
import mutations from './moduleCompanyMutations.js'
import actions from './moduleCompanyActions.js'
import getters from './moduleCompanyGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

