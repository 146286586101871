import axios from '@/axios.js'

export default {
  async acceptLicenseAgreement ({ commit, rootState }) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_API_URL}/aceite_contrato_licenca_uso`)

      /* DEPOIS DE ALTERADO NO SERVIDOR, ALTERO NO LOCALSTORAGE CONFORME ABAIXO */

      const company = JSON.parse(JSON.stringify(rootState.AppActiveUser.company))
      company.aceite_contrato_licenca_uso = 1
      commit('UPDATE_USER_INFO', { company }, { root:true })
      return resp
    } catch (error) {
      return error
    }
  },
  update (context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/company`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
